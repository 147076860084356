<template>
  <div style="width: 100%">
    <bread-crumb :breadcrumb-items="breadcrumbsItems" />

    <div class="section-padding pb-0">
      <div class="container default-padding">
        <div class="primary-heading text-center">
          {{ `Our ${labels.MENU_SERVICES}` }}
        </div>

        <div class="caption">
          {{ labels.PH_SERVICES }}
        </div>
      </div>
    </div>

    <div v-if="!loading">
      <div v-if="services && services.length > 0">
        <div
          v-for="service in services"
          :key="service.id"
          :id="service.id"
          class="service"
          data-aos="fade-up"
        >
          <div class="service__detail container default-padding">
            <div class="section-padding">
              <div class="service__title primary-heading">
                {{ service.name }}
              </div>

              <div class="paragraph" v-html="service.description"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="container default-padding">
      {{ `${labels.LBL_LOADING}...` }}
    </div>

    <get-a-quote />

    <horizontal-client-slider />
  </div>
</template>

<script>
  import { labels } from "@/common";
  import { mapState } from "vuex";

  import BreadCrumb from "@/components/BreadCrumb.vue";
  import GetAQuote from "../components/GetAQuote.vue";
  import HorizontalClientSlider from "../components/HorizontalClientSlider.vue";

  export default {
    name: "Services",

    components: {
      BreadCrumb,
      GetAQuote,
      HorizontalClientSlider,
    },

    data() {
      return {
        labels,
        breadcrumbsItems: [{ name: "Services", path: "" }],
      };
    },

    computed: {
      ...mapState({
        loading: (state) => state.isBusy,
        services: (state) => state.service.services.data,
      }),
    },

    created() {
      this.loadServices();
    },

    methods: {
      loadServices() {
        this.$store.dispatch("service/getAllServices", this.params);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/main.scss";

  .service {
    padding: 3rem 0;

    &__title {
      margin-top: -0.5rem;
      font-size: 1.625rem;
      color: $primary-color;
      text-transform: uppercase;
    }

    &:nth-child(even) {
      background: $background-color;
    }
  }

  @media only screen and (max-device-width: 768px) {
  }
</style>
