<template>
  <content-wrapper :title="labels.LBL_SERVICES" back>
    <template #actions>
      <el-button size="small" type="success" @click="submitForm('serviceForm')">
        <i class="el-icon-check mr-1"></i>
        {{ labels.LBL_SAVE }}
      </el-button>
    </template>

    <el-form
      v-loading="loading"
      :rules="rules"
      ref="serviceForm"
      :model="service"
      label-position="top"
    >
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item prop="name" :label="labels.LBL_NAME">
            <el-input v-model="service.name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="description" :label="labels.LBL_DESCRIPTION">
            <rich-editor v-model="service.description" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </content-wrapper>
</template>

<script>
  import { labels, rules } from "@/common";
  import { mapState } from "vuex";

  import ContentWrapper from "@/components/layouts/AdminContentWrapper.vue";
  import RichEditor from "@/components/RichEditor.vue";

  export default {
    name: "AddService",

    components: {
      ContentWrapper,
      RichEditor,
    },

    data() {
      return {
        labels,
        rules,

        service: {
          name: "",
          description: "",
        },
      };
    },

    computed: {
      ...mapState({
        loading: (state) => state.isBusy,
      }),
    },

    methods: {
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$store.dispatch("service/createService", this.service);
          }
        });
      },
    },
  };
</script>

<style></style>
