<template>
  <div style="width: 100%">
    <bread-crumb :breadcrumb-items="breadcrumbsItems" />
    <div class="container default-padding">
      <div class="section-padding">
        <div class="primary-heading">Our Clients</div>
        <div class="caption mb-8">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Numquam, ab!
        </div>

        <div v-if="!loading">
          <el-row v-if="allClients.length" :gutter="40">
            <el-col
              v-for="(client, index) in allClients"
              :key="client.name"
              :span="24"
              :lg="8"
            >
              <div class="client" data-aos="fade-up">
                <img :src="client.logo_url" class="client__logo" />
                <div class="client__name">{{ client.name }}</div>
              </div>
            </el-col>
          </el-row>
          <div v-else class="text-center">
            No Clients Found
          </div>
        </div>
        <div v-else>{{ `${labels.LBL_LOADING}...` }}</div>
      </div>

      <!-- <client-testimonials /> -->
    </div>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import { labels } from "@/common";

  import BreadCrumb from "../components/BreadCrumb.vue";
  // import ClientTestimonials from "../components/ClientTestimonials.vue";

  export default {
    name: "Clients",

    components: {
      BreadCrumb,
      // ClientTestimonials,
    },

    data() {
      return {
        labels,
        breadcrumbsItems: [{ name: "Clients", path: "" }],
      };
    },

    computed: {
      ...mapState({
        loading: (state) => state.isBusy,
        clients: (state) => state.clients.clients,
      }),

      allClients() {
        return this.clients && this.clients.data;
      },
    },

    created() {
      this.loadClients();
    },

    methods: {
      loadClients() {
        this.$store.dispatch("clients/getAllClients");
      },
    },
  };
</script>

<style lang="scss" scoped>
  .client {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    margin-bottom: 3rem;
    height: 300px;
    box-sizing: border-box;

    &__logo {
      height: 200px;
      width: 200px;
      margin-bottom: 1rem;
    }

    &__name {
      font-size: 1.2rem;
      text-align: center;
    }
  }
</style>
